
import { Options, Vue } from 'vue-class-component';
import FileUpload from '../../atom/transportCost/request/FileUpload.vue'; 
import TextBox from '../../atom/common/TextBox.vue';
import PullDown from '../../atom/common/PullDown.vue';
import CommonButton from '../../atom/common/CommonButton.vue';
import CommonCombo from '../../atom/common/CommonCombo.vue';
import { Getter, Action } from 'vuex-class';
import { PostData } from '../../../models/api/transportCost';
import { officeOptions } from '../../../models/category/office';
import { departmentOptions } from '../../../models/category/department';
import { processOptions } from '../../../models/category/process';
import { User } from '@/store/modules/slack/type';
import { Workflow, TargetUserCode } from '../../../store/modules/transportCost/type';
import { SiteURL } from '@/environment';

interface Flow{
    ProcessingType : number,
    TargetUserCodeList: Array<string>, 
    HasError: boolean,
    Disabled: boolean
}

@Options({
  components: {
    TextBox,
    PullDown,
    CommonButton,
    FileUpload,
    CommonCombo
  }
})
export default class TransportCostRequest extends Vue {

    public officeOptions = officeOptions;
    public departmentOptions = departmentOptions;

    public titleError = '';
    public officeError = '';
    public departmentError = '';
    public fileError = 'ファイルを選択してください';
    public hasError = false;

    @Getter('Slack/token')
    public token!: string;
    @Getter('TransportCost/title')
    public title!: string;
    @Getter('TransportCost/office')
    public office!: string;
    @Getter('TransportCost/department')
    public department!: string;
    @Getter('TransportCost/workflow')
    public workflow!: Array<Workflow>;
    @Getter('Slack/user')
    public user!: User;
    @Getter('TransportCost/file')
    public uploadFile!: Blob;


    @Action('Slack/SendMessage')
    public SendMessage!: (data: {token: string, userCode: string, text:string}) => Promise<boolean>;
    @Action('TransportCost/SetTitle')
    public SetTitle!: (data: string) => Promise<boolean>;
    @Action('TransportCost/SetOffice')
    public SetOffice!: (data: string) => Promise<boolean>;
    @Action('TransportCost/SetDepartment')
    public SetDepartment!: (data: string) => Promise<boolean>;
    @Action('TransportCost/SetFile')
    public SetFile!: (data: Blob) => Promise<boolean>;
    @Action('TransportCost/SetWorkFlow')
    public SetWorkFlow!: (data: Array<Workflow>) => Promise<boolean>;
    @Action('TransportCost/PostData')
    public PostData!: (data: PostData["request"]) => Promise<boolean>;
    @Action('TransportCost/ClearRequest')
    public ClearRequest!: () => Promise<boolean>;
    @Action('Workflow/SetCompleteLabel')
    public SetCompleteLabel!: (data: string) => Promise<boolean>;
    
    private addFlowIndex = 1;

    public flows: Array<Flow> = [];

    beforeMount(){
        if(this.workflow.length){
            this.flows = [];
            for (let i = 0; i < this.workflow.length; i++){
                let userCodeList: string[] = [];
                for(let userCode of this.workflow[i].target_user_code_list){
                    userCodeList.push(userCode.target_user_code);
                }
                this.flows.push({
                    ProcessingType : this.workflow[i].process_category,
                    TargetUserCodeList: userCodeList,
                    Disabled: this.workflow[i].process_category === 0 ? false : true,
                    HasError: false
                });
            }
        }
        else{
            this.initFlows();
        }
        this.flows[this.flows.length - 1].TargetUserCodeList = [`${this.user.id}`]
    }

    initFlows(){
        this.flows = [
            {
                ProcessingType : 0,
                TargetUserCodeList: [],
                Disabled: false,
                HasError: false
            },
            {
                ProcessingType : 1,
                TargetUserCodeList: ['UMT7TCF54'], 
                Disabled: true,
                HasError: false
            },
            {
                ProcessingType : 2,
                TargetUserCodeList: [],
                Disabled: true,
                HasError: false
            },
        ]
    }

    unmounted(){
        this.ClearRequest();
    }

    post(){
        this.clearErrorFlg();
        this.checkData();
        this.checkFlow();
        if(this.hasError) return;
        this.PostData({
        data: {
            sender_code: this.user.id,
            title: this.title,
            office: this.office,
            department: this.department,
            workflows: this.workflow,
            request_category: '1',
            creator_code: this.user.id,
            create_date: new Date(Date.now() + 9 * 3600000).toISOString().split('Z')[0] + '+09:00'
        },
        file: this.uploadFile
        })
        .then(res =>
            {
                for(const userCode of this.flows[0].TargetUserCodeList){
                    this.SendMessage({
                        token: this.token,
                        userCode: userCode,
                        text: `<@${this.user.id}>からワークフロー承認依頼があります。\nワークフローシステムから確認をお願い致します。\nURL：${SiteURL}/TransportCost/List/${res}`
                    })
                }
                this.ClearRequest();
                this.SetCompleteLabel("申請");
                this.$router.push({name: "Complete"});
            }
        );
    }

    addFlow(){
        let flow = {
            ProcessingType : 0,
            TargetUserCodeList: [],
            Disabled: false,
            HasError: false
            };
        this.flows.splice(this.addFlowIndex++, 0, flow);
        this.setWorkFlow();
    }

    deleteFlow(index: number){
        this.addFlowIndex--;
        this.flows.splice(index, 1);
        this.setWorkFlow();
    }

    changeFlowTarget(data: string[], index: number){
        this.flows[index].TargetUserCodeList = data.concat();
        this.setWorkFlow();
    }

    setFileData(file: File){
        var title = new String(file.name).substring(file.name.lastIndexOf('/') + 1); 
        if(title.lastIndexOf(".") != -1)       
        title = title.substring(0, title.lastIndexOf("."));
        this.SetTitle(title);
        this.SetFile(file);
    }

    setWorkFlow(){
        let workFlow:Array<Workflow> = [];
        for(const flow of this.flows){
            let targetWorkFlow:Workflow = {
                process_category: flow.ProcessingType,
                target_user_code_list : []
            };
            for(const targetUserCode of flow.TargetUserCodeList)
            {
                let userCode:TargetUserCode = {
                    target_user_code: targetUserCode
                };
                targetWorkFlow.target_user_code_list.push(userCode);
            }
            workFlow.push(targetWorkFlow);
        }
        this.SetWorkFlow(workFlow);
    }

    getProcessName(processCategory: number){
        return processOptions.find(_=>_.value === processCategory.toString())?.item;
    }

    clearErrorFlg(){
        this.titleError = '';
        this.officeError = '';
        this.departmentError = '';
        this.hasError = false;
        for(const flow of this.flows){
            flow.HasError = false;
        }
    }

    checkData(){
        if(!this.title){
            this.titleError = 'タイトルは必須です';
            this.hasError = true;
        }
        if(!this.office){
            this.officeError = '事業所を選択してください';
            this.hasError = true;
        }
        if(!this.department){
            this.departmentError = '所属部署を選択してください';
            this.hasError = true;
        }
        if(this.uploadFile.constructor.name !== 'File'){
            this.fileError = '添付ファイルは必須です';
            this.hasError = true;
        }
    }

    checkFlow(){
        for(const flow of this.flows){
            if(flow.TargetUserCodeList.length === 0){
                this.hasError = true;
                flow.HasError = true;
            } 
        }
    }
    
}
