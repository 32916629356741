import { ActionTree } from 'vuex';
import { RootState } from '@/store/type';
import { AuthState } from './type';
import * as slack from '@/api/slack'
import * as types from './mutation-types'

export const actions: ActionTree<AuthState, RootState> = {
    [types.SetSession]: async ({ commit }, code: string) => {
        return await slack.getSessionInfo(code)
        .then((res) => {
            commit(types.SetSession, res.data)
        })
        .catch(err => { throw err})
    },
    [types.TokenReflesh]: async ({ commit }, token: string) => {
        return await slack.tokenReflesh(token)
        .then((res) => {
            commit(types.TokenReflesh, res.data)
        })
        .catch(err => { throw err})
    },
    [types.GetMembers]: async ({ commit }, token: string) => {
        return await slack.getMembersInfo(token)
        .then((res) => {
            commit(types.GetMembers, res.data)
        })
        .catch(err => { throw err})
    },
    [types.SendMessage]: async ({ commit }, data:{token:string, userCode: string, text: string}) => {
        return await slack.sendMessage(data.token, data.userCode, data.text)
        .then((res) => {
            commit(types.SendMessage, res.data)
        })
        .catch(err => { throw err})
    }
};

export default actions;