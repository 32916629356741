import { MutationTree } from 'vuex';
import { WorkflowState } from './type';
import { GetWorkflow } from '@/models/api/workflow';
import * as types from './mutation-types'

const mutations: MutationTree<WorkflowState> = {
  [types.GetWorkflow]: (state:WorkflowState, payload:GetWorkflow["response"]) => {
    state.workflow = payload.workflow_for_response_array;
    const currentWorkflow = payload.workflow_for_response_array.find(_=>_.active_flg === 1)
    if(currentWorkflow){
      state.currentWorkflow = currentWorkflow;
    }
    else{
      state.currentWorkflow = {
        workflow_id: 0,
        request_id: 0,
        order: 0,
        process_category: 0,
        comment: "",
        status: 0,
        approver_code: "",
        active_flg: 0,
        target_user_code_list: [],
        creator_code: "",
        create_date: "",
        updater_code: "",
        update_date: ""
      };
    }
  },
  [types.SetCompleteLabel]: (state:WorkflowState, payload:string) => {
    state.completeLabel = payload;
  }
};

export default mutations;