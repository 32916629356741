import { GetterTree } from 'vuex';
import { RootState } from '../../type';
import { WorkflowState } from './type';

const getters: GetterTree<WorkflowState, RootState> = {
  workflow: (state: WorkflowState) => {
    return state.workflow;
  },
  currentWorkflow: (state: WorkflowState) => {
    return state.currentWorkflow;
  },
  completeLabel: (state: WorkflowState) => {
    return state.completeLabel;
  }
};

export default getters;