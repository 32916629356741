import { WorkflowState } from './type'

export const state: WorkflowState = {
  workflow: [],
  currentWorkflow: {
    workflow_id: 0,
    request_id: 0,
    order: 0,
    process_category: 0,
    comment: "",
    status: 0,
    approver_code: "",
    active_flg: 0,
    target_user_code_list: [],
    creator_code: "",
    create_date: "",
    updater_code: "",
    update_date: ""
  },
  completeLabel: ""
};

export default state;