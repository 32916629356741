<template>
    <div class="page-btn">
        <button @click="btnPressed"
            :class="{active: isCurrentPage}"
        >{{ pageNumber }} </button>
    </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        pageNumber: Number,
        curPage: Number
    },
    computed: {
        isCurrentPage() {
            return this.pageNumber === this.curPage;
        }
    },
    methods: {
        btnPressed() {
            this.$emit('changePage', this.pageNumber);
        }
    }
})
export default class PageButton extends Vue {
}
</script>

<style scoped lang="scss">
.page-btn > button{
   color: #fff;
   background: #35495e;
   border: none;
   border-radius: 10%;
   cursor:pointer;
}
.active{
   background: #073190!important; 
}
</style>