import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Index from '../components/index.vue'
import TopView from '../components/organisms/TopView.vue'
import TransportCostIndex from '../components/organisms/transportCost/TransportCostIndex.vue'
import TransportCostRequest from '../components/organisms/transportCost/TransportCostRequest.vue'
import TransportCostApproveList from '../components/organisms/transportCost/TransportCostApproveList.vue'
import TransportCostList from '../components/organisms/transportCost/TransportCostList.vue'
import TransportCostListDetail from '../components/organisms/transportCost/TransportCostListDetail.vue'
import TransportCostComplete from '../components/organisms/transportCost/TransportCostComplete.vue'
import LoginView from '../components/organisms/LoginView.vue'
import CallBackView from '../components/organisms/CallBackView.vue'
import NotFound from '../components/organisms/NotFound.vue'

import { authorizeToken } from './guards'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '',
    component: Index,
    meta: { 
      requiresAuth : true
      },
    children: [
      {
        path: 'top',
        name: 'Top',
        component: TopView,
        alias: ''
      },
      {
        path: 'transportCost',
        name: 'TransportCost',
        component: TransportCostIndex,
        children: [
          {
            path: 'request',
            name: 'Request',
            component: TransportCostRequest
          },
          {
            path: 'ApproveList',
            name: 'ApproveList',
            component: TransportCostApproveList
          },
          {
            path: 'List',
            name: 'List',
            component: TransportCostList
          },
          { 
            path: 'list/:id',
            name: 'ListDetail',
            component: TransportCostListDetail
          },
          { 
            path: 'complete',
            name: 'Complete',
            component: TransportCostComplete
          },
          {
            path: '/:catchAll(.*)',
            name: 'notFound',
            component: NotFound
          }
        ]
      },
      {
        path: '/:catchAll(.*)',
        name: 'notFound',
        component: NotFound
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/callback',
    name: 'callback',
    component: CallBackView
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

router.beforeEach(authorizeToken)

export default router;
