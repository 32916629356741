
import { Options, Vue } from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Request as  RequestModel} from "../../../models/api/request";
import Paginaiton from "@/components/molecules/transportCost/Paginaiton.vue"

@Options({
  components: {
    Paginaiton
  }
})
export default class TransportCostApproveList extends Vue {

    @Getter('Request/requestList')
    private requestList!: Array<RequestModel>;

}
