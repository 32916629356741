
import { Options, Vue } from 'vue-class-component';
import FooterCopyright from '../atom/FooterCopyright.vue'; 

@Options({
  components: {
    FooterCopyright
  }
})
export default class Footer extends Vue {}
