import { ActionTree } from 'vuex';
import { RootState } from '@/store/type';
import { RequestState } from './type';
import * as request from '@/api/request'
import * as types from './mutation-types'

export const actions: ActionTree<RequestState, RootState> = {
    [types.GetRequestList]: async ({ commit }, data: any) => {
        return await request.getRequestList(data)
        .then((res) => {
            commit(types.GetRequestList, res.data)
        })
        .catch(err => { throw err})
    },
    [types.GetSendRequestList]: async ({ commit }, data: any) => {
        return await request.getSendRequestList(data)
        .then((res) => {
            commit(types.GetSendRequestList, res.data)
        })
        .catch(err => { throw err})
    },
    [types.GetApproveRequestList]: async ({ commit }, data: any) => {
        return await request.getApproveRequestList(data)
        .then((res) => {
            commit(types.GetApproveRequestList, res.data)
        })
        .catch(err => { throw err})
    }
};

export default actions;