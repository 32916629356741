
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    menus: Array,
    icon: String
  }
})
export default class Header extends Vue {}
