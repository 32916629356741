import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a068dedc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = ["required", "placeholder", "readonly"]
const _hoisted_3 = { class: "ms-3 text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      required: _ctx.Required,
      placeholder: _ctx.Placeholder,
      readonly: _ctx.readonly,
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeText && _ctx.changeText(...args))),
      type: "text"
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.value]
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.ErrorMessage), 1)
  ]))
}