
import { Options, Vue } from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Request as  RequestModel} from "../../../models/api/request";
import Paginaiton from "@/components/molecules/transportCost/Paginaiton.vue"

@Options({
  components: {
    Paginaiton
  }
})
export default class TransportCostList extends Vue {

    private isSendHistory = true;
    private isApproveHistory = false;

    private RequestList: Array<RequestModel> = [];

    @Getter('Request/sendRequestList')
    private sendRequestList!: Array<RequestModel>;

    @Getter('Request/approveRequestList')
    private approveRequestList!: Array<RequestModel>;

    beforeMount(){
        this.RequestList = this.sendRequestList;
    }

    showSendHistory(){
        this.isSendHistory = true;
        this.isApproveHistory = false;
        this.RequestList = this.sendRequestList;
    }

    showApproveHistory(){
        this.isSendHistory = false;
        this.isApproveHistory = true;
        this.RequestList = this.approveRequestList;
    }

}
