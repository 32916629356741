
import { Options, Vue } from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import TopButton from '../../atom/top/TopButton.vue';
import Title from '../../atom/top/Title.vue';
import { Request as  RequestModel} from "../../../models/api/request";
import { User } from '@/store/modules/slack/type';

@Options({
  components: {
    Title,
    TopButton
  }
})
export default class Request extends Vue {

    @Getter('Request/requestList')
    private requestList!: Array<RequestModel>;
    @Getter('Slack/user')
    private user!: User;

    @Action('Request/GetRequestList')
    private GetRequestList!: (userCode: string) => Promise<boolean>;
    @Action('Request/GetSendRequestList')
    private GetSendRequestList!: (userCode: string) => Promise<boolean>;
    @Action('Request/GetApproveRequestList')
    private GetApproveRequestList!: (userCode: string) => Promise<boolean>;

    private NewTransportCost =
    {
      label: "申請",
      path: "/TransportCost/Request"
    }
    private TransportCostApproveList =
    {
      label: "承認",
      path: "/TransportCost/ApproveList",
      count: "0"
    }
    private TransportCostList =
    {
      label: "確認",
      path: "/TransportCost/List"
    }
    private NewEquipment =
    {
      label: "申請",
      path: "/Equipment/Request"
    }
    private EquipmentList =
    {
      label: "承認・確認",
      path: "/Equipment/Approval",
      count: "10"
    }
    private NewAddEmployee =
    {
      label: "申請",
      path: "/AddEmployee/Request"
    }
    private AddEmployeeList =
    {
      label: "承認・確認",
      path: "/AddEmployee/Approval",
      count: "50"
    }
    private NewRetirement =
    {
      label: "申請",
      path: "/Retirement/Request"
    }
    private RetirementList =
    {
      label: "承認・確認",
      path: "/Retirement/Approval",
      count: "13"
    }

    beforeMount(){
    this.GetRequestList(this.user.id)
    .then(() =>
      {
        this.TransportCostApproveList.count = this.requestList.length.toString();
      }
    );
    this.GetSendRequestList(this.user.id);
    this.GetApproveRequestList(this.user.id);
  }
}
