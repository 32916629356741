import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38198acb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "outer" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "topbutton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonButton = _resolveComponent("CommonButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, _toDisplayString(_ctx.completeLabel) + "が完了致しました ", 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_CommonButton, {
            label: `TOP画面へ戻る`,
            onButtonClick: _ctx.navigateTop
          }, null, 8, ["onButtonClick"])
        ])
      ])
    ])
  ]))
}