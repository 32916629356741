export const APIURL = 'https://api.ask-sys.com'
export const SiteURL = 'https://workflow.ask-sys.com'
export const SlackAPIURL = 'https://slack.com/api'
export const SlackLoginURL = 'https://slack.com/oauth/v2/authorize?client_id=325849019188.4554894508883&scope=chat:write,users:read,users:read.email&user_scope=users:read,users:read.email'
export const ClientId = '325849019188.4554894508883';
export const ClientSecret = 'b9b9afdb71b84d5f497312617d91c80e';
export const RedirectURL = 'https://workflow.ask-sys.com/CallBack'

//export const APIURL = 'http://localhost:8000'
//export const SiteURL = 'https://localhost:8080'
//export const SlackAPIURL = 'https://slack.com/api'
//export const SlackLoginURL = 'https://slack.com/oauth/v2/authorize?client_id=3648401928055.3675672814929&scope=users:read,users:read.email,chat:write&user_scope=users:read,users:read.email'
//export const ClientId = '3648401928055.3675672814929';
//export const ClientSecret = 'b07bfac893039e9a23a8ee534660cde5';
//export const RedirectURL = 'https://localhost:8080/CallBack'