import axios, { AxiosRequestConfig } from 'axios'
import { APIURL } from '@/environment';

const headers : { [key: string]: string } = {}
headers['Content-type'] = 'application/json'

const config:AxiosRequestConfig = {
    method: undefined,
    url: APIURL,
    headers: headers,
    data: undefined
}

const formDataHeaders : { [key: string]: string } = {}
headers['Content-type'] = 'multipart/form-data'

const formDataConfig:AxiosRequestConfig = {
    method: undefined,
    url: APIURL,
    headers: formDataHeaders,
    data: undefined
}

export const getRequestList = (receiver_code: string) => {
    return axios.get(`${APIURL}/request/receiver?receiver_code=${receiver_code}`)
    .then(res => res)
    .catch(err => {throw err});
}

export const getSendRequestList = (creator_code: string) => {
    return axios.get(`${APIURL}/request/create?creator_code=${creator_code}`)
    .then(res => res)
    .catch(err => {throw err});
}

export const getApproveRequestList = (creator_code: string) => {
    return axios.get(`${APIURL}/request/history?user_code=${creator_code}`)
    .then(res => res)
    .catch(err => {throw err});
}