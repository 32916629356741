
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    InitValue: { type: String, required: false, default: null },
    Placeholder: { type: String, required: false, default: null },
    readonly: { type: String, required: false, default: null },
    ErrorMessage: { type: String, required: false, default: null },
  },
  data: () => ({
    value: '', 
  }),
  beforeMount() {
    this.value = this.InitValue;
  },
  beforeUpdate(){
   this.value = this.InitValue;
  },
  methods: {
    changeText:function (){
      this.$emit('changeText', this.value);
    }
  },
  emits: ['changeText'],
})
export default class TextBox extends Vue {
    
}
