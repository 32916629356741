
import { Options, Vue } from 'vue-class-component';
import Request from '../molecules/top/Request.vue'; 

@Options({
  components: {
    Request
  }
})
export default class TopView extends Vue {}
