
import { Vue } from 'vue-class-component';
import { Getter, Action } from 'vuex-class';

export default class CallBackView extends Vue {
  @Getter('Slack/token')
  private token!: string;

  @Action('Slack/SetSession')
  private SetSession!: (code: string) => Promise<boolean>;

  @Action('Slack/GetMembers')
  private GetMembers!: (token: string) => Promise<boolean>;

  created(){
    let code  = this.$route.query.code as string
    this.SetSession(code)
    .then(() =>
      {
        this.GetMembers(this.token);
      }
    )
    .then(() =>
      {
        this.$router.push('/top');
      }
    );
  }
}
