import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-84f1a57e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = {
  class: "ms-3 cursor-pointer",
  title: "フォーマットファイルをダウンロード"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  class: "ms-3 cursor-pointer",
  title: "申請Wikiを開く"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 0,
  class: "ms-3"
}
const _hoisted_7 = {
  key: 1,
  class: "text-danger ms-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createElementVNode("input", {
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectedFile && _ctx.selectedFile(...args))),
        type: "file",
        name: "file",
        accept: ".xlsx"
      }, null, 32),
      _createTextVNode(" ファイルを選択 ")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: _ctx.formatFileUrl,
        class: "bi bi-download"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        href: _ctx.wikiUrl,
        class: "bi bi-question-circle",
        target: "_blank",
        rel: "noopener noreferrer"
      }, null, 8, _hoisted_5)
    ]),
    ((_ctx.fileName !== '' || _ctx.ErrorMessage === 'ファイルを選択してください') && _ctx.errMsg === '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.fileName !== '' ? _ctx.fileName : _ctx.ErrorMessage), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.errMsg != '' ? _ctx.errMsg :  _ctx.ErrorMessage), 1))
  ]))
}