import store from '@/store'

export const authorizeToken = (to, from, next) => {
  if (to.matched.some((page) => page.meta.requiresAuth) && !isLogin())
   next({ name: 'Login' })
  else next()
}

const isLogin = () => {
  return store.getters["Slack/token"]
}