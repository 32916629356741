import { ActionTree } from 'vuex';
import { RootState } from '@/store/type';
import { WorkflowState } from './type';
import * as workflow from '@/api/workflow'
import * as types from './mutation-types'

export const actions: ActionTree<WorkflowState, RootState> = {
    [types.GetWorkflow]: async ({ commit }, data: any) => {
        return await workflow.getWorkflow(data)
        .then((res) => {
            commit(types.GetWorkflow, res.data)
        })
        .catch(err => { throw err});
    },
    [types.UpdateStatus]: async ({ commit }, data: any) => {
        return await workflow.updateStatus(data)
        .catch(err => { throw err});
    },
    [types.SetCompleteLabel]: async ({ commit }, data: any) => {
        return commit(types.SetCompleteLabel, data);
    }
};

export default actions;