
import { Options, Vue } from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Member } from '@/store/modules/slack/type';
import { requestOptions } from '@/models/category/request';
import { statusOptions } from '@/models/category/status';
import { departmentOptions } from '@/models/category/department';

@Options({
  props: {
    requestList: { type: Array, required: false }
  }
})
export default class ListTable extends Vue {

    @Getter('Slack/members')
    private members!: Array<Member>;

    getStatus(statusCode: number){
        return statusOptions.find(_=>_.value === statusCode.toString())?.item;
    }

    getMemberName(userCode: string){
        return this.members.find(_=>_.id === userCode)?.name;
    }

    getRequestCategoryName(requestCategory: number){
        return requestOptions.find(_=>_.value === requestCategory.toString())?.item;
    }

    getDepartmentName(department: number){
        return departmentOptions.find(_=>_.value === department.toString())?.item;
    }

    getDate(date: string){
        const targetDate = new Date(date);
        return targetDate.toLocaleDateString() + '　'+ targetDate.toLocaleTimeString();
    }
}
