
import { Vue, Options } from 'vue-class-component';
import { Getter } from 'vuex-class';
import CommonButton from '../../atom/common/CommonButton.vue';

@Options({
  components: {
    CommonButton
  }
})
export default class TransportCostComplete extends Vue {

    @Getter('Workflow/completeLabel')
    private completeLabel!: string;

    navigateTop(){
        this.$router.push('/Top');
    }
}
