
import { Options, Vue } from 'vue-class-component';
import LoginForm from '../molecules/login/LoginForm.vue'; 
import FooterCopyright from '../atom/FooterCopyright.vue'; 
import store from '../../store';

@Options({
  components: {
    LoginForm,
    FooterCopyright
  }
})
export default class LoginView extends Vue {
    handleLogin (authInfo :any) {
        return store.dispatch('login', authInfo)
        .then(() => {
            this.$router.push({ path: '/' })
        })
        .catch((err: any) => { throw err })
    }
}
