import { GetterTree } from 'vuex';
import { RootState } from '../../type';
import { TransportCostState } from './type';

const getters: GetterTree<TransportCostState, RootState> = {
  title: (state: TransportCostState) => {
    return state.send.title;
  },
  office: (state: TransportCostState) => {
    return state.send.office;
  },
  department: (state: TransportCostState) => {
    return state.send.department;
  },
  file: (state: TransportCostState) => {
    return state.send.file;
  },
  workflow: (state: TransportCostState) => {
    return state.send.workflows;
  },
  request: (state: TransportCostState) => {
    return state.request;
  }
};

export default getters;