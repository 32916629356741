import { GetterTree } from 'vuex';
import { RootState } from '../../type';
import { RequestState } from './type';

const getters: GetterTree<RequestState, RootState> = {
  requestList: (state: RequestState) => {
    return state.request;
  },
  sendRequestList: (state: RequestState) => {
    return state.sendRequest;
  },
  approveRequestList: (state: RequestState) => {
    return state.approveRequest;
  }
};

export default getters;