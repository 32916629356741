
import { Options,Vue } from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { GetRequest } from '../../../models/api/transportCost';
import { GetWorkflow, TargetUserCode, UpdateStatus, Workflow} from '../../../models/api/workflow';
import { Member, User } from '@/store/modules/slack/type';
import CommonCombo from '../../atom/common/CommonCombo.vue';
import ApproveBox from '../../molecules/transportCost/ApproveBox.vue';
import { requestOptions } from '@/models/category/request';
import { statusOptions } from '@/models/category/status';
import { departmentOptions } from '@/models/category/department';
import { officeOptions } from '@/models/category/office';
import { processOptions } from '@/models/category/process';
import { APIURL, SiteURL } from '@/environment';

@Options({
  components: {
    CommonCombo,
    ApproveBox
  }
})
export default class TransportCostListDetail extends Vue {

    private url = "";
    private id = "";
    private dispFlg = false;

    @Getter('Slack/token')
    private token!: string;
    @Getter('TransportCost/request')
    private request!: GetRequest["response"];
    @Getter('Workflow/workflow')
    private workflows!: Array<Workflow>;
    @Getter('Workflow/currentWorkflow')
    private currentWorkflow!: Workflow;
    @Getter('Slack/user')
    private user!: User;
    @Getter('Slack/members')
    private members!: Array<Member>;

    @Action('Slack/SendMessage')
    private SendMessage!: (data: {token: string, userCode: string, text:string}) => Promise<boolean>;
    @Action('TransportCost/GetRequest')
    private GetRequest!: (data: string) => Promise<boolean>;
    @Action('Workflow/GetWorkflow')
    private GetWorkflow!: (data: string) => Promise<boolean>;
    @Action('Workflow/UpdateStatus')
    private UpdateStatus!: (data: UpdateStatus["request"]) => Promise<boolean>;
    @Action('Workflow/SetCompleteLabel')
    private SetCompleteLabel!: (data: string) => Promise<boolean>;

    beforeMount(){
        this.id = this.$route.params.id.toString();
        this.url = `${APIURL}/transportCostRequest/file?request_id=${this.id}`
        this.GetRequest(this.id);
        this.GetWorkflow(this.id);
    }

    getOfficeName(officeCode: number){
        return officeOptions.find(_=>_.value === officeCode.toString())?.item;
    }

    getStatus(statusCode: number, processCategory: number){
        if(statusCode === 0){
            switch(processCategory){
            case 0 : return '申請中';
            case 1 : return '決裁中';
            case 2 : return '確認中';
            }
        }
        else{
            return statusOptions.find(_=>_.value === statusCode.toString())?.item;
        }
    }

    getMemberName(userCode: string){
        return this.members.find(_=>_.id === userCode)?.name;
    }

    getRequestCategoryName(requestCategory: number){
        return requestOptions.find(_=>_.value === requestCategory.toString())?.item;
    }

    getDepartmentName(department: number){
        return departmentOptions.find(_=>_.value === department.toString())?.item;
    }

    getDate(date: string){
        if(!date) return '';
        const targetDate = new Date(date);
        return targetDate.toLocaleDateString() + '　'+ targetDate.toLocaleTimeString();
    }

    getProcessCategoryName(processCategory: number){
        return processOptions.find(_=>_.value === processCategory.toString())?.item;
    }

    getTargetUserCodeList(targetUserCodeList: Array<TargetUserCode>){
        let targetList: Array<string> = [];
        for(let targetUserCode of targetUserCodeList){
            targetList.push(targetUserCode.target_user_code);
        }
        return targetList;
    }

    getStatusName(status: number){
        return statusOptions.find(_=>_.value === status.toString())?.item;
    }

    approve(comment: string){
        let status = 0;
        if(!this.currentWorkflow) return;
        switch(this.currentWorkflow.process_category){
            case 0 : status = 1; break;
            case 1 : status = 2; break;
            case 2 : status = 3; break;
        }
        const postData: UpdateStatus["request"] = {
            workfow_id: this.currentWorkflow.workflow_id,
            comment: comment,
            status: status,
            updater_code: this.user.id
        };
        this.UpdateStatus(postData)
        .then(() =>
            {
                let nextWorkflow = this.workflows.find(_=>_.order === (this.currentWorkflow.order+1))
                if(nextWorkflow) {
                    for(const userCode of nextWorkflow?.target_user_code_list){
                        this.SendMessage({
                            token: this.token,
                            userCode: userCode.target_user_code,
                            text: `<@${this.request.creator_code}>からワークフロー承認依頼があります。\nワークフローシステムから確認をお願い致します。\nURL：${SiteURL}/TransportCost/List/${this.request.request_id}`
                        })
                    }
                } 
            }
        ).finally(() =>
            {
                this.SetCompleteLabel("承認");
                this.$router.push({name:'Complete'});
            }
        );
    }

    reject(comment: string){
        if(!this.currentWorkflow) return;
        const postData: UpdateStatus["request"] = {
            workfow_id: this.currentWorkflow.workflow_id,
            comment: comment,
            status: 4,
            updater_code: this.user.id
        };
        this.UpdateStatus(postData)
        .then(() =>
            {
                this.SendMessage({
                    token: this.token,
                    userCode: this.request.creator_code,
                    text: `<@${this.user.id}>によって申請が否認されました。\nワークフローシステムから確認をお願い致します。\nURL：${SiteURL}/TransportCost/List/${this.request.request_id}`
                }) 
            }
        ).finally(() =>
            {
                this.SetCompleteLabel("否認");
                this.$router.push({name:'Complete'});
            }
        );
    }

}
