import axios, { AxiosRequestConfig } from 'axios'
import { GetWorkflow, UpdateStatus } from '../models/api/workflow'
import { APIURL } from '@/environment';

const headers : { [key: string]: string } = {}
headers['Content-type'] = 'application/json'

const config:AxiosRequestConfig = {
    method: undefined,
    url: APIURL,
    headers: headers,
    data: undefined
}

const formDataHeaders : { [key: string]: string } = {}
headers['Content-type'] = 'multipart/form-data'

const formDataConfig:AxiosRequestConfig = {
    method: undefined,
    url: APIURL,
    headers: formDataHeaders,
    data: undefined
}

export const getWorkflow = (request_id: GetWorkflow["request"]) => {
    return axios.get(`${APIURL}/workflows?request_id=${request_id}`)
    .then(res => res)
    .catch(err => {throw err});
}

export const updateStatus = (postData: UpdateStatus["request"]) => {
    return axios.put(`${APIURL}/workflows/status`, postData, formDataConfig)
    .then(res => res)
    .catch(err => {throw err});
}