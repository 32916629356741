import Vuex, { StoreOptions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import { RootState } from "./type";
import { slack } from "./modules/slack";
import { request } from "./modules/request";
import { workflow } from "./modules/workflow";
import { transportCost } from "./modules/transportCost";

const ls = new SecureLS({ isCompression: false });

export const store: StoreOptions<RootState> = {
  state: {
    version: "1.0.0",
  },
  modules: {
    Slack: slack,
    Request: request,
    Workflow: workflow,
    TransportCost: transportCost,
  },
  plugins: [
    createPersistedState({
      key: "workflowsystem",
      paths: ["Slack"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
    createPersistedState({
      key: "workflowsystem",
      paths: ["TransportCost"],
      storage: window.sessionStorage,
    }),
  ],
};

export default new Vuex.Store<RootState>(store);
