
import { Options, Vue } from 'vue-class-component';

@Options({

})
export default class ApproveBox extends Vue {
    public dispFlg = false;
    public text = '';

    approve(){
      this.$emit('approve', this.text);
      this.clearText();
    }

    reject(){
      this.$emit('reject', this.text);
      this.clearText();
    }

    clearText(){
        this.dispFlg = false
        this.text = '';
    }
}
