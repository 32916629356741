
import { Options, Vue } from 'vue-class-component';

@Options({
  props:{
    buttonItem : Object
  }
})
export default class TopButton extends Vue {
}
