<template>
  <router-view/>
</template>

<style lang="scss">

*,*::before,*::after{box-sizing:border-box}
*{margin:0}
html,body{height:100%; background-color: rgb(241,242,246,0.2);}
body{line-height:1.5;-webkit-font-smoothing:antialiased}
img,picture,video,canvas,svg{display:block;max-width:100%}
input,button,textarea,select{font:inherit}
p,h1,h2,h3,h4,h5,h6{overflow-wrap:break-word}
ol, ul, li {list-style: none;margin: 0;padding: 0;}
a{text-decoration: none;font-weight: normal;}
#root,#__next{isolation:isolate}

body{
  height: 100%;
  font-family: Verdana, "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
