import { TransportCostState } from './type'

export const state: TransportCostState = {
  sender_code: '',
  send: {
    title: '',
    department: '',
    office: '',
    request_category: '',
    workflows: [],
    creator_id: '',
    create_date: '',
    file: new Blob(),
  },
  request: {
    transportcost_request_id: 0,
    request_id: 0,
    title: '',
    office: 0,
    department: 0,
    creator_code: '',
    create_date: '',
    updater_code: '',
    update_date: ''
  }
};

export default state;