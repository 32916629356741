import { ActionTree } from 'vuex';
import { RootState } from '@/store/type';
import { TransportCostState } from './type';
import * as transportCost from '@/api/transportCost'
import * as types from './mutation-types'

export const actions: ActionTree<TransportCostState, RootState> = {
    [types.SetTitle]: async ({ commit }, data: any) => {
        commit(types.SetTitle, data)
    },
    [types.SetOffice]: async ({ commit }, data: any) => {
        commit(types.SetOffice, data)
    },
    [types.SetDepartment]: async ({ commit }, data: any) => {
        commit(types.SetDepartment, data)
    },
    [types.SetFile]: async ({ commit }, data: any) => {
        commit(types.SetFile, data)
    },
    [types.SetWorkFlow]: async ({ commit }, data: any) => {
        commit(types.SetWorkFlow, data)
    },
    [types.PostData]: async ({ commit }, data: any) => {
        return await transportCost.postData(data)
        .then(res => res.data)
        .catch(err => { throw err})
    },
    [types.GetRequest]: async ({ commit }, data: any) => {
        return await transportCost.getRequest(data)
        .then((res) => {
            commit(types.GetRequest, res.data)
        })
        .catch(err => { throw err});
    },
    [types.ClearRequest]: ({ commit }) => {
        return commit(types.ClearRequest)
    }
};

export default actions;