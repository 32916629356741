import { AuthState } from './type'

export const state: AuthState = {
  token: '',
  refreshToken: '',
  user: {
    id: '',
    name: '',
    email: '',
    Icon: ''
  },
  members:[],
  sendMessage: false
};

export default state;