import { MutationTree } from 'vuex';
import { AuthState } from './type';
import { SessionInfo, MemberInfo, SendMessage, TokenReflesh } from '@/models/api/slack';
import * as types from './mutation-types'

const mutations: MutationTree<AuthState> = {
  [types.SetSession]: (state:AuthState, payload:SessionInfo["response"]) => {
    state.token = payload.access_token;
    state.refreshToken = payload.refresh_token;
    state.user = {
      id: payload.authed_user.id,
      name: '',
      email: '',
      Icon: ''
    };
  },
  [types.TokenReflesh]: (state:AuthState, payload:TokenReflesh["response"]) => {
    state.token = payload.access_token;
    state.refreshToken = payload.refresh_token;
  },
  [types.GetMembers]: (state:AuthState, payload:MemberInfo["response"]) => {
    state.members = [];
    for(const member of payload.members.filter(_=>_.deleted === false)){
      if(state.user.id === member.id){
        state.user = {
          id: member.id,
          name: member.profile.display_name,
          email: member.profile.email,
          Icon: member.profile.image_48
        };
      }
      state.members.push({
        id: member.id,
        name: member.profile.display_name,
        email: member.profile.email,
        Icon: member.profile.image_48
      });
    }
  },
  [types.SendMessage]: (state:AuthState, payload:SendMessage["response"]) => {
      state.sendMessage = payload.ok;
  }
};

export default mutations;