import { Module } from 'vuex';
import { AuthState } from './type'
import { RootState } from '@/store/type'
import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const slack: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};