import axios from 'axios'
import { SlackAPIURL, ClientId, ClientSecret, RedirectURL } from '@/environment';

export const getSessionInfo = (code: string) => {
    const params = new URLSearchParams();
    params.append('client_id', ClientId);
    params.append('client_secret', ClientSecret);
    params.append('code', code);
    params.append('redirect_uri', RedirectURL);

    return axios.post(`${SlackAPIURL}/oauth.v2.access`, params)
    .then(res => res)
    .catch(err => {throw err});
}

export const tokenReflesh = (token: string) => {
    const params = new URLSearchParams();
    params.append('client_id', ClientId);
    params.append('client_secret', ClientSecret);
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', token);

    return axios.post(`${SlackAPIURL}/oauth.v2.access`, params)
    .then(res => res)
    .catch(err => {throw err});
}

export const getUserInfo = (token: string) => {
    const params = new URLSearchParams();
    params.append('client_id', ClientId);
    params.append('client_secret', ClientSecret);
    params.append('token', token);
    return axios.post(`${SlackAPIURL}/auth.test`, params)
    .then(res => res)
    .catch(err => {throw err});
}

export const getMembersInfo = (token: string) => {
    const params = new URLSearchParams();
    params.append('token', token);

    return axios.post(`${SlackAPIURL}/users.list`, params)
    .then(res => res)
    .catch(err => {throw err});
}

export const sendMessage = (token: string, userCode: string, text: string) => {
    const params = new URLSearchParams();
    params.append('token', token);
    params.append('channel', userCode);
    params.append('text', text);

    return axios.post(`${SlackAPIURL}/chat.postMessage`, params)
    .then(res => res)
    .catch(err => {throw err});
}