
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    modelValue: { type: String, required: false },
    options: { type: Array, required: true },
    defaultOption: { type: Boolean, required: false, default: null },
    ErrorMessage: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: null }
  }
})
export default class PullDown extends Vue {

    handleChange = (e: Event) => {
    if (!(e.target instanceof HTMLSelectElement)) {
        return;
    }
    this.$emit('Value', e.target.value);
    }
}

