import { GetterTree } from 'vuex';
import { RootState } from '../../type';
import { AuthState } from './type';

const getters: GetterTree<AuthState, RootState> = {
  token: (state: AuthState) => {
    return state.token;
  },
  refleshToken: (state: AuthState) => {
    return state.refreshToken;
  },
  user: (state: AuthState) => {
    return state.user;
  },
  members: (state: AuthState) => {
    return state.members;
  },
  sendMessage: (state: AuthState) =>{
    return state.sendMessage;
  }
};

export default getters;