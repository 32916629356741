import { MutationTree } from 'vuex';
import { RequestState } from './type';
import { GetRequestList, GetSendRequestList, GetApproveRequestList } from '@/models/api/request';
import * as types from './mutation-types'

const mutations: MutationTree<RequestState> = {
  [types.GetRequestList]: (state:RequestState, payload:GetRequestList["response"]) => {
    state.request = payload.sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
  },
  [types.GetSendRequestList]: (state:RequestState, payload:GetSendRequestList["response"]) => {
    state.sendRequest = payload.sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
  },
  [types.GetApproveRequestList]: (state:RequestState, payload:GetApproveRequestList["response"]) => {
    state.approveRequest = payload.sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
  }
};

export default mutations;