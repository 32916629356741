import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d45aafb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "dispbtn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dispFlg = !_ctx.dispFlg))
    }, "承認・否認"),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.text) = $event)),
        placeholder: "コメントがある場合は入力してください",
        rows: "5",
        cols: "20",
        name: "comment"
      }, null, 512), [
        [_vModelText, _ctx.text]
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("input", {
          class: "btn approve",
          type: "button",
          value: "承認",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.approve($event)))
        }),
        _createElementVNode("input", {
          class: "btn reject",
          type: "button",
          value: "否認",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reject($event)))
        })
      ])
    ], 512), [
      [_vShow, _ctx.dispFlg]
    ])
  ], 64))
}