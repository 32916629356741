
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    label: { type: String, required: true },
    disabled: { type: Boolean, default: false }
  }
})
export default class CommonButton extends Vue {

    Click(e){
      this.$emit('buttonClick', e.target.value);
    }
    
}
