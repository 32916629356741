import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05adeda7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    class: "btn",
    type: "button",
    disabled: _ctx.disabled,
    value: _ctx.label,
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.Click($event)), ["stop"]))
  }, null, 8, _hoisted_1))
}