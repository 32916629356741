
import { Options, Vue } from 'vue-class-component';
import Header from './organisms/Header.vue';
import Footer from './organisms/Footer.vue'
import { Getter, Action } from 'vuex-class';
import { User, Member } from '@/store/modules/slack/type';

@Options({
  components: {
    Header,
    Footer
  }
})
export default class Index extends Vue {
  @Getter('Slack/token')
  private token!: string;
  @Getter('Slack/refleshToken')
  private refleshToken!: string;

  @Getter('Slack/user')
  private user!: User;

  @Getter('Slack/members')
  private members!: Array<Member>;

  @Action('Slack/TokenReflesh')
  private TokenReflesh!: (token: string) => Promise<boolean>;

  private menus = [
    {
      label: "TOP",
      path: "/top",
    },
    {
      label: "費用申請",
      path: "/TransportCost/Request",
    },
    /*
    {
      label: "社員追加申請",
      path: "./top",
    },
    {
      label: "退職者申請",
      path: "./top",
    },
    {
      label: "備品申請",
      path: "./top",
    }
    */
  ]

  beforeMount(){
    this.TokenReflesh(this.refleshToken);
  }
}
