import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = ["required", "disabled", "value"]
const _hoisted_3 = {
  key: 0,
  value: ""
}
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "ms-3 text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("select", {
      required: _ctx.required,
      disabled: _ctx.disabled,
      value: _ctx.modelValue,
      onChange: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleChange($event)), ["stop"]))
    }, [
      (_ctx.defaultOption)
        ? (_openBlock(), _createElementBlock("option", _hoisted_3, [
            _renderSlot(_ctx.$slots, "defaultOption", {}, () => [
              _createTextVNode(" 選択してください ")
            ])
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          value: option.value,
          key: option.value
        }, _toDisplayString(option.item), 9, _hoisted_4))
      }), 128))
    ], 40, _hoisted_2),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.ErrorMessage), 1)
  ]))
}