<template>
  <div>
    <ListTable :requestList="displayItems" />
    <div class="page-btns">
      <template v-for="(n, index) in limitedPages" :key="index">
        <PageButton
          v-if="n !== null"
          class="page-btn"
          @changePage="changePage"
          :pageNumber="n"
          :curPage="curPage"
        />
        <div v-if="n === null" class="ellipsis">...</div>
      </template>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import ListTable from "@/components/molecules/transportCost/ListTable.vue";
import PageButton from "@/components/molecules/transportCost/PageButton.vue";

@Options({
  components: {
    ListTable,
    PageButton,
  },
  props: {
    items: Array,
    itemNumPerPage: Number,
  },
  data() {
    return {
      curPage: 1,
      pageNum: 0,
    };
  },
  created() {
    this.calcPageNum();
  },
  beforeUpdate() {
    this.calcPageNum();
  },
  computed: {
    displayItems() {
      const startIdx = (this.curPage - 1) * this.itemNumPerPage;
      const endIdx = startIdx + this.itemNumPerPage;
      return this.items.slice(startIdx, endIdx);
    },
    limitedPages() {
      const range = 2; // 現在のページから前後5ページ
      const pages = [];

      // 最初のページ
      pages.push(1);

      if (this.curPage - range - 1 > 1) {
        pages.push(null);
      }

      // 現在のページ周辺のページを追加
      for (let i = this.curPage - range; i <= this.curPage + range; i++) {
        if (i > 1 && i < this.pageNum) {
          pages.push(i);
        }
      }

      if (this.pageNum - this.curPage - range > 1) {
        pages.push(null);
      }

      // 最後のページ
      if (this.pageNum > 1) {
        pages.push(this.pageNum);
      }

      return pages;
    },
  },
  methods: {
    changePage(value) {
      this.curPage = value;
    },
    calcPageNum() {
      this.pageNum = Math.ceil(this.items.length / this.itemNumPerPage);
    },
  },
})
export default class Pagination extends Vue {}
</script>

<style scoped lang="scss">
.page-btns {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.page-btn:nth-of-type(n + 2) {
  margin-left: 1rem;
}
.ellipsis {
  margin-left: 1rem;
  display: flex;
  align-items: center;
}
</style>
