import { MutationTree } from 'vuex';
import { TransportCostState, Workflow } from './type';
import { GetRequest } from '@/models/api/transportCost';
import * as types from './mutation-types'

const mutations: MutationTree<TransportCostState> = {
  [types.SetTitle]: (state:TransportCostState, payload:string) => {
    state.send.title = payload;
  },
  [types.SetOffice]: (state:TransportCostState, payload:string) => {
    state.send.office = payload;
  },
  [types.SetDepartment]: (state:TransportCostState, payload:string) => {
    state.send.department = payload;
  },
  [types.SetFile]: (state:TransportCostState, payload:Blob) => {
    state.send.file = payload;
  },
  [types.SetWorkFlow]: (state:TransportCostState, payload:Array<Workflow>) => {
    state.send.workflows = payload;
  },
  [types.GetRequest]: (state:TransportCostState, payload:GetRequest["response"]) => {
    state.request = payload;
  },
  [types.ClearRequest]: (state:TransportCostState) => {
    state.send = {
      title: '',
      department: '',
      office: '',
      request_category: '',
      workflows: [],
      creator_id: '',
      create_date: '',
      file: new Blob(),
    }
  }
};

export default mutations;