
import { Options, Vue } from 'vue-class-component';
import { APIURL } from '@/environment';

@Options({
  props: {
    ErrorMessage: { type: String, required: false, default: null },
  }
})
export default class FileUpload extends Vue {

    public formatFileUrl = `${APIURL}/transportCostRequest/file?request_id=0`;
    public wikiUrl = `https://wiki.asking.co.jp/public/internal-documents/keihi`;

    public fileName = "";
    public errMsg = "";

    selectedFile(e){
        this.errMsg = '';
        e.preventDefault();
        let files = e.target.files;
        if (files[0]) {
            this.fileName = files[0].name;
            if (!files[0].name.match('.(xlsx)$')) {
                this.fileName = '';
                this.errMsg = '拡張子が xlsx 以外のファイルはアップロードできません';
                return;
            }
            this.$emit('uploadFile', files[0]);
        }
    }
}
