import { SelectOption } from "../common/general";

export const departmentOptions: SelectOption[] = [
    {value: '0', item: 'システム開発部'},
    {value: '1', item: 'アウトソーシング開発部'},
    {value: '2', item: '大阪支社'},
    {value: '3', item: '事業推進部'},
    {value: '4', item: '開発事業本部'},
    {value: '5', item: 'ソリューション営業統括部'},
    {value: '6', item: 'WEB事業部'},
    {value: '7', item: '総務部'},
    {value: '8', item: '役員'}
];