
import { Options, Vue } from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Member } from '@/store/modules/slack/type';
import { MultiselectUserOprion } from '@/models/common/general'
import Multiselect from '@vueform/multiselect'


@Options({
    components: {
      Multiselect,
    },
    props: {
      InitValue: Array,
      HasError:{ Boolean, default: false },
      Disabled: { Boolean, default: false }
    },
    data: () => ({
      mode: 'tags',
      value: [],
      placeholder: 'Select employees',
      closeOnSelect: false,
      canClear: false,
      searchable: true,
      trackBy: 'name',
      label: 'name'   
    }),
    beforeMount() {
      this.value = this.InitValue.concat();
    },
    beforeUpdate() {
      this.value = this.InitValue.concat();
    },
    methods: {
      changeValue:function (){
        this.$emit('changeFlowTarget', this.value);
      },
      Remove:function (){
        this.$emit('changeFlowTarget', this.value);
      },
      handleClear(){
        this.$emit('changeFlowTarget', this.value);
      }
    },
    computed: {
      options:function (){
        let comboOptions: Array<MultiselectUserOprion> = [];
        for(const member of this.members){
            if(member.email){
                comboOptions.push({
                    value: member.id,
                    name: member.name,
                    image: member.Icon,
                    email: member.email,
                });
            }
        }
        return comboOptions;
      }
    },
    emits: ['changeFlowTarget'],
})
export default class CommonCombo extends Vue {  
    @Getter('Slack/members')
    public members!: Array<Member>;
}
