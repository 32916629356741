
import { Vue } from 'vue-class-component';
import { Getter } from 'vuex-class';
import { SlackLoginURL } from '@/environment';

export default class SlackLoginButton extends Vue {
    @Getter('Slack/token')
    private token!: string;

    Click(){
      if(this.token){
        this.$router.push('/top');
      }
      else{
        window.location.href = SlackLoginURL;
      }
    }
    
}
