
import { Options, Vue } from 'vue-class-component';
import LoginLogo from '../../atom/login/login-logo.vue';
import SlackLoginButton from '../../atom/login/slack-login-button.vue'; 

@Options({
  components: {
    LoginLogo,
    SlackLoginButton
  }
})
export default class LoginForm extends Vue {}
