import axios, { AxiosRequestConfig } from 'axios';
import { PostData } from '../models/api/transportCost';
import { APIURL } from '@/environment';


const headers : { [key: string]: string } = {}
headers['Content-type'] = 'application/json'

const config:AxiosRequestConfig = {
    method: undefined,
    url: APIURL,
    headers: headers,
    data: undefined
}

const formDataHeaders : { [key: string]: string } = {}
headers['Content-type'] = 'multipart/form-data'

const formDataConfig:AxiosRequestConfig = {
    method: undefined,
    url: APIURL,
    headers: formDataHeaders,
    data: undefined
}

export const postData = (postData: PostData["request"]) => {
    const data = new FormData();
    data.append('transport_cost_request',JSON.stringify(postData.data));
    data.append('file', postData.file)
    return axios.post(`${APIURL}/transportCostRequest`,data, formDataConfig)
    .then(res => res)
    .catch(err => {throw err});
}

export const getRequest = (request_id: string) => {
    return axios.get(`${APIURL}/transportCostRequest?request_id=${request_id}`)
    .then(res => res)
    .catch(err => {throw err});
}

